import { render, staticRenderFns } from "./follow_reply.vue?vue&type=template&id=5e3e4d33&scoped=true&"
import script from "./follow_reply.vue?vue&type=script&lang=ts&"
export * from "./follow_reply.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e3e4d33",
  null
  
)

export default component.exports